<template>
    <div>
        <div class="footer-basic">
            <footer>
                    <div class="social"><a href="https://twitter.com/enkisearch"><b-icon-twitter>twitter</b-icon-twitter></a><a href="https://www.linkedin.com/company/enki-search/"><b-icon-linkedin></b-icon-linkedin></a></div>
                    <div class="contact-address">
                    <span>
                        ENKI<br>
                        3 Inns Court,<br>
                        Winetavern Street,<br>
                        Dublin 8, D08Y078<br>
                    </span>
                    <div class="contact-details">
                        <a href="mailto:info@enki.ie">info@enki.ie</a><span> | Tel: +353 1 488 0990 </span>
                    </div>
                    <p class="copyright">Copyright 2025 ENKI  |  All Rights Reserved  |  ENKI Law Searchers Ltd  | CRO Number  712647 </p>
                </div>
            </footer>

        </div>
    </div>
</template>

<script>

export default {
  name: 'AppFooter',
  props: {
    msg: String
  }
}
</script>

<style lang="scss" scoped>

.footer-basic{
   position: relative;
   left: 0;
   bottom: 0;
   width: 100%;
   background-color: #463b35;
   color: white;
   text-align: center;
   font-size: 14px;

}
.contact-address{
    text-align: center;
    padding-bottom: 25px;
    color: white;
}
.contact-details a{
    color: yellow;
}
.footer-basic .copyright{
    margin-top: 15px;
    font-size: 13px;
    margin-bottom: 0;
}
.social a{
    color: #5B3D3D;
    background-color: white;
    padding: 9px 12px;
    font-size: 18px;
    width: 10px;
    text-decoration: none;
    border-radius: 50%;
    position: relative;
    margin: 0.5rem
}
.social{
    text-align: center;
    margin: 0 auto;
    padding: 20px;
    position: relative;
}

</style>
